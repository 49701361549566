@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;800&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background-color: #f5f5f5 !important;
}

.navbar {
  background-color: #003354 !important;
  display: block !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 999;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  display: inline !important;
}

.row {
  margin: 0;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 500px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.card {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.15) !important;
}
.card:hover {
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.15) !important;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}



.menu-active::after {
  content: "";
  display: block;
  margin: auto;
  height: 2px;
  width: 100% !important;
  background: #ffffff !important;
  /* transition: width 0.5s ease, background-color 0.5s ease; */
}
.menu-active::after {
  width: 0;
  background: transparent;
}

.outlets-link::after{
  content: "";
  display: block;
  margin: 0;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s linear, background-color 0.5s ease;
}
.outlets-link:hover::after {
  width: 100%;
  background: #003354;
}

.nav-menu-bar::after {
  content: "";
  display: block;
  margin: 0;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.5s linear, background-color 0.5s ease;
}

.nav-menu-bar:hover::after {
  width: 100%;
  background: #ffffff;
}
